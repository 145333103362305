import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color50: '#f7f9f9',
    color100: '#f0f3f3',
    color200: '#e1e7e8',
    color300: '#d9e1e2',
    color400: '#b8bdbf',
    color500: '#97999b',
    color600: '#74777a',
    color700: '#53565a',
    color800: '#37393c',
    color900: '#1c1d1e'
  },
  default: {
    color50: '#d5e9e2',
    color100: '#aad3c6',
    color200: '#80bda9',
    color300: '#55a68c',
    color400: '#2b9070',
    color500: '#007a53',
    color600: '#006242',
    color700: '#004932',
    color800: '#003121',
    color900: '#001811'
  },
  accent: {
    color50: '#e5f0d6',
    color100: '#cbe2ad',
    color200: '#b1d385',
    color300: '#97c45c',
    color400: '#7db633',
    color500: '#63a70a',
    color600: '#4f8608',
    color700: '#3b6406',
    color800: '#284304',
    color900: '#142102'
  },
  positive: {
    color50: '#e5f0d6',
    color100: '#cbe2ad',
    color200: '#b1d385',
    color300: '#97c45c',
    color400: '#7db633',
    color500: '#63a70a',
    color600: '#4f8608',
    color700: '#3b6406',
    color800: '#284304',
    color900: '#142102'
  },
  negative: {
    color50: '#f9dbda',
    color100: '#f3b8b4',
    color200: '#ee948f',
    color300: '#e87069',
    color400: '#e24d44',
    color500: '#dc291e',
    color600: '#b02118',
    color700: '#841912',
    color800: '#58100c',
    color900: '#2c0806'
  },
  special: {
    color50: '#fce8d5',
    color100: '#f8d1aa',
    color200: '#f5bb80',
    color300: '#f1a455',
    color400: '#ee8d2b',
    color500: '#ea7600',
    color600: '#bb5e00',
    color700: '#8c4700',
    color800: '#5e2f00',
    color900: '#2f1800'
  }
};

const variant: ACPVariant = {
  id: 'www.transact711.com',
  productTitle: 'Transact Prepaid',
  productType: 'gpr',
  accessDomain: {
    subdomain: 'www',
    domain: 'transact711',
    topLevelDomain: 'com'
  },
  colors,
  legacyAcpTheme: 'transact-7-eleven',
  legacyAcpBrandName: 'transact711',
  legacyLegosTheme: 'transact-7-eleven',
  status_bar_background_color: colors.default.color500,
  status_bar_foreground_color: 'light',
  contactInfo: {
    name: 'Transact Prepaid Mastercard',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'CustomerService@transact711.com',
    fax_formatted: '(512) 857-0263',
    phone_formatted: '(844) 711-4711',
    phone: '8447114711',
    phone_international: '737-220-8956',
    balance_check_phone: '8663877363',
    balance_check_phone_formatted: '1-866-387-7363',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '5Us7NeWk6WVjGLsAnKioSk',
      appsFlyerBannerKey: '7e9fdc1d-c68b-4cf0-aa21-47a72a92ad19',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: true,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: 'mobile',
      showAccountDisplayNickname: true,
      showAcquisitionLink: false,
      showATMFriendlyCards: false,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: true,
      showScanCardRegistration: true,
      showMarketingSiteLink: false,
      showFreeAtmFinderLink: true,
      showWUTransfers: true,
      showReloadLocationLink: true
    },
    misc: {
      atmNetwork: '7-Eleven',
      distributor: 'transact711',
      layoutComponentOverride: false,
      loader: 'generic',
      storeNameOverride: '7eleven',
      whiteListedAnchorPatterns: [
        'www(.|-)transact711(.com|-[a-z].test.aus.netspend.net|.local)'
      ],
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: '#007A53',
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: '#63A70A',
      marketingSiteUrl: 'https://www.transact711.com',
      marketingSiteLinkText: 'Transact711.com',
      locationSearchMax: '25',
      iOSAppId: '1149023957'
    },
    content: {
      signUpContent: "Don't have a card? Sign up today."
    },
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'external-loyalty',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'points-learn-more',
      'family-cards',
      'bank-transfers',
      'handoff',
      'debit-card-transfers',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'dashboard-hub',
      'register-hub',
      'profile-hub',
      'change-password-hub',
      'debit-card-transfers-disbursement',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials',
      'dd-tracker'
    ]
  }
};

export default variant;
